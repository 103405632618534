import { graphql, Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Drift } from 'react-live-chat-loader'
import BlogBanner from '../../components/BlogBanner'
import BlogHeader from '../../components/BlogHeader'
import LiveChatLayout from '../../components/LiveChatLayout'
import Seo from '../../components/seo'
import BackArrow from '../../icons/back-arrow.svg'

export default function BlogPost({ data }) {
  return (
    <LiveChatLayout>
      <Seo title="Introdução à Transcrição ao Vivo (Em Tempo Real)" />
      <div className="drift-wrapper">
        <Drift icon="B" color="rgb(222, 48, 105)" className="circle" />
      </div>
      <section className="blog-section">
        <div className="blog-content">
          <Link to="/blog" className="back-link">
            <img src={BackArrow} alt="Início" />
          </Link>
          <BlogHeader
            title="Introdução à Transcrição ao Vivo (Em Tempo Real)"
            date="1 de Outubro de 2021"
          />
          <p>
            A maior parte de nossa vida profissional gira em torno da comunicação. 
            Escrevemos atas de reuniões, emails e trocamos ideias.
          </p>
          <p>
            Com agendas lotadas e centenas de ideias competindo por nossa mente 
            e tempo, é fácil pular informações cruciais.
          </p>
          <p>
            Para muitos de vocês, VoxRec já está ajudando na transcrição de 
            reuniões, emails, consultas, entrevistas e outras tarefas onde as 
            informações devem ser capturadas e acionadas.
          </p>
          <p>
            Alguns ainda não adotaram a tecnologia de voz para texto em suas comunicações 
            diárias, o que prejudica seu fluxo de trabalho sem que eles percebam.
          </p>
          <p>
            Para muitas pessoas, a comunicação por voz é um desafio. Pessoas com 
            deficiências, dislexia e aqueles que não falam a sua língua nativa 
            têm problemas para entender o que você está dizendo - o que 
            não deveria ser o caso nunca.
          </p>
          <p>
            Hoje, estamos expandindo nosso serviço de voz para texto existente, 
            introduzindo a Transcrição ao Vivo em nosso app. Sua gravação é 
            capturada normalmente, mas a transcrição é produzida na tela em 
            tempo real.
          </p>
          <p>
            Esperamos que este recurso ajude ainda mais nossos usuários, especialmente 
            com a inclusão em ambientes e tarefas que, de outra forma, seriam 
            desafiadoras para participar.
          </p>
          <BlogBanner
            title="Transcrição Instantânea de Voz em Texto"
            text="Veja e leia o que está sendo dito sem um intérprete e sem esperar. Experimente a Transcrição ao Vivo do VoxRec."
            withBackground
            picture={
              <StaticImage
                src="../../images/blog/blog-live-transcribe.png"
                className="banner__image"
                alt="VoxRec em iOS"
              />
            }
            appStoreLink={data.site.siteMetadata.appStoreLink}
          />
          <h2>Acessibilidade Digital com Transcrição de Voz em Texto</h2>
          <p>
            A Organização Mundial de Saúde estima que o número de pessoas com deficiência 
            auditiva e surdos seja de cerca de 466 milhões em todo o mundo.
          </p>
          <p>
            Os governos estão pressionando agora mais do que nunca pela igualdade para 
            todos. Apps de transcrição ao vivo em tempo real podem concretizar essa 
            visão.
          </p>
          <p>
            Existem milhões de outras pessoas que tem dificuldade para digitar ou fazer 
            anotações. Seja por dislexia, disgrafia, trauma ou outras 
            deficiências que restringem os movimentos das mãos ou afetam a cognição, a transcrição 
            ao vivo pode ajudar essas pessoas a participar de conversas, reuniões e eventos 
            online ou offline.
          </p>
          <h2>Transcrição ao Vivo em Reuniões e Conferências Internacionais</h2>
          <p>
            Vivemos em uma época em que a maioria das reuniões e conferências inclui pessoas
            de diferentes países. A transcrição ao vivo ajuda os profissionais em 
            reuniões de diretoria internacional a ouvir e ver o que está em 
            discussão. Isso garante que eles se concentrem em tópicos e ideias, em vez de
            tentar entender a linguagem.
          </p>
          <h2>Legendagem em Tempo Real em Aulas e Palestras</h2>
          <p>
            Professores e alunos poderiam ser muito mais eficientes se eles
            implementassem a tecnologia de reconhecimento de voz por IA em suas 
            aulas.
          </p>
          <p>
            Alunos com deficiência ou não fluente na língua falada podem 
            usar o VoxRec para ver o que o palestrante está dizendo em tempo real como texto. 
            Isso os ajudará a aprender, compreender e responder melhor ao 
            contexto da apresentação.
          </p>
          <p>
            Após o término da aula, VoxRec enviará a eles uma transcrição 
            precisa por email que eles podem usar como notas de estudo.
          </p>
          <p>
            Da mesma forma, conferências e apresentações podem usar legendas ao vivo para 
            ajudar a elaborar as palavras do palestrante, o que é muito útil quando 
            não há tradutor para surdos e deficientes auditivos.
          </p>
          <h2>Experimente a Transcrição ao Vivo no VoxRec</h2>
          <p>
            Você pode ativar e experimentar o recurso Transcrição ao Vivo na 
            tela de gravação do app VoxRec. Toque na face do robô (🤖), 
            fale naturalmente e observe o texto que aparece na tela enquanto você 
            fala. Mágica!
          </p>
          <p>
            Você pode ajustar o tamanho da fonte diretamente na tela para garantir que o 
            texto seja fácil de ler.
          </p>
          <p>
            Ao parar a gravação, você terá o áudio e a transcrição 
            disponíveis para revisão ou exportação.
          </p>
          <p>
            Observação - a transcrição ao vivo não pode ser enviada automaticamente 
            para você por email da mesma forma que quando você faz uma transcrição regular 
            na nuvem de um áudio pré-gravado.
          </p>
          <p>
            Se você desejar saber mais, verifique nossas {' '}
            <a href="/faq">Perguntas Frequentes atualizadas em nosso site</a> ou dentro do app.
          </p>
          <p>
            Nos avise o que você acha sobre o novo recurso de Transcrição ao Vivo 
            - todo feedback é bem-vindo!
          </p>
        </div>
      </section>
    </LiveChatLayout>
  )
}

export const data = graphql`
  query {
    site {
      siteMetadata {
        appStoreLink
      }
    }
  }
`
